import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { styled } from "@mui/material/styles";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Pagination = styled("ul")(`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin-top: 4rem;
`);

const Seed = ({ data, pageContext }) => {
  const { nexttitle, nextslug, prevtitle, prevslug } = pageContext;
  const seedNode = data.seed;
  return (
    <Layout>
      <main>
        <Helmet>
          <title>{`${seedNode.variety.name} (${seedNode.primary_id}) | ${config.siteTitle}`}</title>
        </Helmet>
        <div>
          <h3>{seedNode.variety.name}</h3>
          <h4>{seedNode.primary_id}</h4>
        </div>
        {seedNode.images[0]?.image_sharp && (
          <GatsbyImage
            image={getImage(seedNode.images[0]?.image_sharp)}
            alt={seedNode.variety.name}
          />
        )}
        <div>{seedNode.variety.description}</div>
        <nav>
          <Pagination>
            <li>
              <Link to={prevslug} rel="prev">
                ← {prevtitle}
              </Link>
            </li>
            <li>
              <Link to={nextslug} rel="next">
                {nexttitle}→
              </Link>
            </li>
          </Pagination>
        </nav>
      </main>
    </Layout>
  );
};

export default Seed;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query SeedByPrimaryId($primary_id: String!) {
    seed(primary_id: { eq: $primary_id }) {
      primary_id
      images {
        image_label
        image_sharp {
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            transformOptions: { fit: COVER }
          )
        }
      }
      variety {
        name
        description
      }
    }
  }
`;
